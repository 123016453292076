<template>
  <div>
    <head-bar :title="null">
      <template v-slot:left>
        <head-bar-link @click="onBackClick">
          <img src="../assets/arrow-left.svg" alt="" aria-hidden="true" />
          Terug
        </head-bar-link>
      </template>
    </head-bar>

    <app-layout classes="page">
      <div class="p-2 p-md-3" v-if="activePage">
        <div class="article__media mb-2 mb-md-3" v-if="activePage.mediaItem">
          <img
            :src="activePage.mediaItem.url"
            :alt="activePage.mediaItem.alt"
            width="640"
            height="422"
          >
        </div>

        <h1 class="article__heading mb-2 mb-md-3">
          {{ activePage.translation.title }}
        </h1>

        <blocks-renderer :blocks="activePage.translation.blocks" />
      </div>
    </app-layout>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AppLayout from './AppLayout.vue';
import BlocksRenderer from '../components/BlocksRenderer.vue';
import HeadBar from '../components/HeadBar.vue';
import HeadBarLink from '../components/HeadBarLink.vue';

export default {
  inject: ['$meta'],

  components: {
    HeadBarLink,
    HeadBar,
    BlocksRenderer,
    AppLayout,
  },

  created() {
    this.loadPage();
  },

  unmounted() {
    this.$store.commit('setActivePage', null);
  },

  computed: {
    ...mapGetters([
      'activePage',
      'pageLoadingState',
    ]),
  },

  methods: {
    loadPage() {
      const { identifier } = this.$route.meta;

      this.$store.dispatch('loadPage', identifier)
        .then(() => {
          this.$meta({
            title: this.activePage.translation.meta.title,
            description: this.activePage.translation.meta.description,
            robots: this.activePage.translation.meta.robots,
          });
        });
    },

    onBackClick() {
      this.$router.go(-1);
    },
  },
};
</script>

<style>
.app-layout.page {
  background-color: #fff;
  padding-top: 3.5rem;
}

.page__media {
  border-radius: .875rem;
  overflow: hidden;
  padding-bottom: 66.666%;
  position: relative;
}

.page__media img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: auto;
}

.page__heading {
  font-size: 1.125rem;
  font-weight: 700;
  color: var(--purple);
}
</style>
